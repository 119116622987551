const model = ({
  price,
  insurance,
  taxes,
  hoa,
  rent,
  extraPayments = 0,
  n = 30,
  resale = 30,
  fees = 18489.53,
  downpaymentRate = 0.2,
  mortgageRate = 0.03,
  rentIncrease = 0.03,
  yearlyAppreciation = 0.03,
  rentalIncomeTaxRate = 0.3,
  chargesIncrease = 0.02,
  propertyTaxIncrease = 0.02,
  capitalGainsTax = 0.22,
}) => {
  const sum = (arr, key) => arr.map((a) => a[key]).reduce((a, b) => a + b, 0);

  const model = [];

  const schedule = loanSchedule({
    price: price,
    insurance: insurance,
    taxes: taxes,
    hoa: hoa,
    n: n,
    resale: resale,
    downpaymentRate: downpaymentRate,
    mortgageRate: mortgageRate,
    extraPayments: extraPayments,
  });

  for (let i = 0; i < Math.max(n, resale); i++) {
    const slice = schedule.slice(i * 12, (i + 1) * 12);

    const appreciation = price * Math.pow(1 + yearlyAppreciation, i);

    const propertyTaxes =
      sum(slice, "taxes") * Math.pow(1 + propertyTaxIncrease, i);
    const commonCharges =
      sum(slice, "insurance") * Math.pow(1 + chargesIncrease, i);

    const interest = sum(slice, "interest");
    const assesment = sum(slice, "hoa");
    const principal = sum(slice, "principal");

    const rentalIncome = rent * 12 * Math.pow(1 + rentIncrease, i);
    const incomeTax =
      (rentalIncome + propertyTaxes + commonCharges + assesment + interest) *
      rentalIncomeTaxRate;
    const rentalIncomeTax = incomeTax < 0 ? 0 : incomeTax;

    const loan = slice[slice.length - 1].loan;

    const downpayment = i === 0 ? price * downpaymentRate : 0;
    const resale_value = i === resale - 1 ? appreciation - loan : 0;
    const fees_value = i === 0 ? fees : 0;

    const netIncome =
      rentalIncome -
      rentalIncomeTax -
      downpayment +
      principal +
      propertyTaxes +
      commonCharges +
      assesment +
      interest -
      fees_value +
      resale_value;

    const r = {
      downpayment: downpayment,
      fees: i === 0 ? fees : 0,
      resale: resale_value,
      appreciation: appreciation,
      rentalIncome: rentalIncome,
      principal: principal,
      propertyTaxes: propertyTaxes,
      rentalIncomeTax: rentalIncomeTax,
      interest: interest,
      expenses: commonCharges + assesment,
      netIncome: netIncome,
      loan: loan,
    };

    model.push(r);
  }

  return model.slice(0, resale);
};

const loanSchedule = ({
  price,
  insurance,
  taxes,
  hoa,
  extraPayments = 0,
  fees = 18489.53,
  n = 10,
  resale = 10,
  downpaymentRate = 0.2,
  mortgageRate = 0.03,
}) => {
  const downpayment = price * downpaymentRate;
  const mortgage = price - downpayment;
  const pt = -Math.abs(pmt(mortgageRate, n * 12, mortgage));

  let loan = mortgage;

  let schedule = [];

  for (let i = 0; i < Math.max(n, resale); i++) {
    for (let j = 0; j < 12; j++) {
      const payment = Math.ceil(loan) > 0 ? pt : 0;
      const totalMonthlyPayment = payment - insurance - taxes - hoa;
      const interest = Math.min(0, -loan * (mortgageRate / 12));
      let principal =
        payment - interest - (Math.ceil(loan) > 0 ? extraPayments : 0);

      if (loan + principal <= 0) {
        principal = -loan;
        loan = 0;
      } else {
        loan = loan + principal;
      }

      const r = {
        year: i + 1,
        month: j + 1,
        payment: payment,
        insurance: -insurance,
        taxes: -taxes,
        hoa: -hoa,
        totalMonthlyPayment: totalMonthlyPayment,
        principal: principal,
        interest: interest,
        loan: loan,
      };
      schedule.push(r);
    }
  }
  return schedule;
};

const pmt = (rate, nper, pv) => {
  if (rate === 0) return pv / nper;

  const v = 1 + rate / 12;
  const t = -(nper / 12) * 12;
  return -(pv * (rate / 12)) / (1 - Math.pow(v, t));
};

module.exports = {
  model,
};
